import React from "react"
import {withPrefix} from "gatsby"
import Layout from "@layout/layout"
import SEO from "@layout/seo"
import HeaderSubpage from "@layout/header-subpage"

export default ({isEn}) => {
  return (
    <Layout isEn={isEn}>
      <HeaderSubpage isEn={isEn}/>
      <SEO
        isEn={isEn}
        title={isEn ? 'Thank you - donation support' : 'Dziękujemy - Wsparcie darczyńców | Organizacje humanitarne'}
        description={isEn ? 'Support the foundation. Help improve the level of treatment in the world! Support our initiatives by donating money!' : 'Wspomóż fundację. Przyczyń się do poprawy poziomu leczenia na świecie! Wesprzyj nasze inicjatywy przekazując środki finansowe - darowiznę!'}
      />
      return (
      <section className="section wrapper text-center imgbg image-mask-bg"
               style={{backgroundImage: `url(${withPrefix('wp-uploads/2016/07/IMG_0010.jpg')})`}}>
        <section className="padding">
          <div className="row">
            <div className="large-6 large-centered medium-12 small-12 columns">
              <p className="margin-bottom50">
                <img
                src={withPrefix('images/help/white_heart.svg')}
                alt="pomoc dla potrzebujących"/></p>

              <h1 className="margin-bottom100">{isEn ? 'Thank you' : 'Dziękujemy'}</h1>

              <div className="wow fadeInUp margin-bottom100" data-wow-duration="1.5s" style={{visibility: 'hidden'}}>
              </div>
              <p>
                {isEn ? 'Join us' : 'Dołącz do nas'}
              </p>
              <h2>
                <a href="https://www.facebook.com/sasafoundation/" className="white social-icon">
                  <i className="fa fa-facebook"></i>
                </a>
                <a href="https://twitter.com/sasa_foundation"
                   className="white social-icon">
                  <i className="fa fa-twitter"></i>
                </a>
                <a href="https://www.linkedin.com/company-beta/17908816/" className="white social-icon">
                  <i className="fa fa-linkedin"></i>
                </a>
                <a href="https://www.instagram.com/sasafoundation/"
                   className="white social-icon">
                  <i className="fa fa-instagram"></i>
                </a>
              </h2>
            </div>
          </div>
        </section>
      </section>
    </Layout>
  )
}
